var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { "padding-bottom": "50px" },
      style: {
        background:
          _vm.backColor && _vm.curClassify === -1 ? _vm.backColor : "#F0F1F5",
      },
      attrs: { id: "pageIndex" },
    },
    [
      _c(
        "div",
        { staticClass: "defaultBg", staticStyle: { "padding-top": "92px" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "fixed",
                top: "0",
                width: "100%",
                "z-index": "3",
              },
              style: _vm.headStyle,
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-container paddingh20",
                  staticStyle: { height: "44px" },
                },
                [
                  _c("div", { staticClass: "flex-container" }, [
                    (_vm.themeD === 3 && _vm.themeBg) ||
                    _vm.backColor ||
                    _vm.headBgImg ||
                    _vm.newerList[0]
                      ? _c("img", {
                          staticClass: "logo",
                          attrs: {
                            alt: "",
                            src: "//image1.51tiangou.com/tgou2/img2/index/title-tgou-logo_2.png",
                            height: "20",
                          },
                        })
                      : _c("img", {
                          staticClass: "logo",
                          attrs: {
                            src: "//image1.51tiangou.com/tgou2/img2/index/title-tgou-logo.png",
                            alt: "",
                            height: "20",
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "lh-20 fs-12 slogan",
                        class:
                          (_vm.themeD === 3 && _vm.themeBg) ||
                          _vm.backColor ||
                          _vm.headBgImg ||
                          _vm.newerList[0]
                            ? "text-white"
                            : "text-dark",
                        staticStyle: { "margin-left": "8px" },
                      },
                      [
                        _vm._v(
                          "\n                        专柜好货线上价\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex-container logoBarSearch",
                        staticStyle: {
                          "border-radius": "200px",
                          padding: "4px",
                          width: "260px",
                          background: "#F8F8F8",
                        },
                        attrs: { "data-scp": "search.0" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              path: "search",
                              query: {
                                type: "index",
                                placeholder: "放心购物上天狗",
                              },
                            })
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "flex-container" }, [
                          _c("img", {
                            staticStyle: { "margin-left": "4px" },
                            attrs: {
                              src: "//image1.51tiangou.com/tgou2/img2/index/icon_search.png",
                              width: "24",
                              height: "24",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "text-limit1 text-left text-light fs-12",
                            staticStyle: { "margin-left": "8px" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.searchBar.placeholder ||
                                  _vm.searchBar.defaultText
                              ),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "bg-red fs-12 lh-24 text-white",
                            staticStyle: {
                              "border-radius": "200px",
                              padding: "0 12px",
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/mine/message/index.html",
                        "data-scp": "noti.00",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pos-r overflow-h",
                          staticStyle: { width: "24px", height: "24px" },
                        },
                        [
                          _c("img", {
                            staticClass: "pos-a",
                            attrs: {
                              src:
                                (_vm.themeD === 3 && _vm.themeBg) ||
                                _vm.backColor ||
                                _vm.headBgImg ||
                                _vm.newerList[0]
                                  ? "//image1.51tiangou.com/tgou2/img2/index/icon-message.png"
                                  : "//image1.51tiangou.com/tgou2/img2/index/icon-message-2.png",
                              width: "24",
                              height: "24",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pos-r",
                  staticStyle: { padding: "8px 48px 8px 12px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-container",
                      staticStyle: {
                        "border-radius": "200px",
                        padding: "4px",
                        background: "#f8f8f8",
                      },
                      attrs: { "data-scp": "search.0" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            path: "search",
                            query: {
                              type: "index",
                              placeholder: "放心购物上天狗",
                            },
                          })
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "flex-container" }, [
                        _c("img", {
                          staticStyle: { "margin-left": "4px" },
                          attrs: {
                            src: "//image1.51tiangou.com/tgou2/img2/index/icon_search.png",
                            width: "24",
                            height: "24",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "text-limit1 text-left text-light fs-12",
                          staticStyle: { "margin-left": "8px" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.searchBar.placeholder ||
                                _vm.searchBar.defaultText
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "bg-red fs-12 lh-24 text-white",
                          staticStyle: {
                            "border-radius": "200px",
                            padding: "0 12px",
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isWeixin
                    ? _c(
                        "a",
                        {
                          staticClass: "pos-a",
                          staticStyle: {
                            width: "30px",
                            height: "36px",
                            right: "9px",
                            top: "5px",
                          },
                          attrs: {
                            href: "/mine/idCode.html",
                            "data-scp": "code.0",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                (_vm.themeD === 3 && _vm.themeBg) ||
                                _vm.backColor ||
                                _vm.headBgImg ||
                                _vm.newerList[0]
                                  ? "//image1.51tiangou.com/tgou2/img2/index/icon-idcode-2.png"
                                  : "//image1.51tiangou.com/tgou2/img2/index/icon-idcode.png",
                              width: "30",
                              height: "36",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isWeixin
                    ? _c("div", {
                        staticClass: "pos-a",
                        staticStyle: {
                          width: "30px",
                          height: "36px",
                          right: "9px",
                          top: "1px",
                        },
                        attrs: { "data-scp": "code.0" },
                        domProps: { innerHTML: _vm._s(_vm.barCodeEntry) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.idCodeGuideInfoIndex
                    ? _c(
                        "div",
                        {
                          staticClass: "pos-a tip-idcode",
                          staticStyle: { right: "0", top: "48px" },
                          attrs: { "data-scp": "wzdl.0" },
                          on: {
                            click: function ($event) {
                              _vm.idCodeGuideInfoIndex = false
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "//image1.51tiangou.com/tgou2/img2/index/tip-idcode.png",
                              alt: "",
                              width: "163",
                              height: "81",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.twelveTag.items.length
        ? _c(
            "div",
            {
              ref: "twelveTagScroll",
              staticClass: "scroll-container",
              staticStyle: { margin: "8px 12px 0" },
              style: _vm.themeD === 3 && "margin-bottom: 8px",
              attrs: { id: "twelveTagScroll" },
            },
            _vm._l(_vm.twelveTag.items, function (item) {
              return _c("div", { staticStyle: { "margin-right": "8px" } }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: item.clickUrl,
                      "data-scp": item.scpCD,
                      "data-bk": item.bk,
                      "data-trace-id": "",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: item.imageUrl, width: "64", height: "24" },
                    }),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.classifyTabs && _vm.classifyTabs.items.length
        ? _c(
            "div",
            {
              staticStyle: { "padding-top": "40px" },
              style: {
                "margin-bottom":
                  _vm.curClassify === -1 && _vm.newerList[0] ? "0" : "8px",
              },
            },
            [
              _c(
                "div",
                {
                  ref: "refTab",
                  staticClass: "pos-f paddingl10",
                  style: _vm.classifyStyle,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "scroll-container float-left fs-14",
                      staticStyle: { width: "calc(100% - 40px)" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dis-i paddingh20 js-fltab",
                          class:
                            _vm.curClassify === -1
                              ? "text-bolder fs-16"
                              : "fs-14",
                          style: _vm.newerList[0]
                            ? "color: #fff"
                            : _vm.classifyTabs.indexColor
                            ? "color: " + _vm.classifyTabs.indexColor
                            : _vm.backColor || _vm.classifyTabs.imageUrl
                            ? "color: #fff"
                            : "color: #000",
                          attrs: { "data-scp": "fltab.0", "data-trace-id": "" },
                          on: {
                            click: function ($event) {
                              return _vm.changeClassify(-1)
                            },
                          },
                        },
                        [_vm._v("首页")]
                      ),
                      _vm._v(" "),
                      _vm.classifyAdv
                        ? _c(
                            "a",
                            {
                              staticClass: "js-fltab",
                              staticStyle: { "vertical-align": "text-top" },
                              attrs: {
                                href: _vm.classifyAdv.clickUrl,
                                "data-scp": _vm.classifyAdv.scpCD,
                                "data-bk": _vm.classifyAdv.bk,
                                "data-trace-id": "",
                              },
                            },
                            [
                              _c("img", {
                                style: _vm.newerList[0]
                                  ? "filter: brightness(100);"
                                  : "",
                                attrs: {
                                  src: _vm.classifyAdv.imageUrl,
                                  alt: "",
                                  height: "18px",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.classifyTabs.items, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "dis-i paddingh20 js-fltab fs-14",
                            class: {
                              "text-bolder fs-16": _vm.curClassify === index,
                              "text-white":
                                _vm.backColor ||
                                _vm.newerList[0] ||
                                _vm.classifyTabs.imageUrl,
                              "text-dark":
                                _vm.curClassify !== index &&
                                !(
                                  _vm.backColor ||
                                  _vm.newerList[0] ||
                                  _vm.classifyTabs.imageUrl
                                ),
                              "text-black":
                                _vm.curClassify === index &&
                                !item.color &&
                                !(
                                  _vm.backColor ||
                                  _vm.newerList[0] ||
                                  _vm.classifyTabs.imageUrl
                                ),
                            },
                            style: {
                              color: _vm.newerList[0] ? "#fff" : item.color,
                              "vertical-align":
                                _vm.curClassify === index ? "" : "top",
                            },
                            attrs: {
                              "data-scp":
                                "fltab." +
                                (_vm.classifyAdv ? index + 2 : index + 1),
                              "data-bk": item.bk,
                              "data-trace-id": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeClassify(index, item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.title) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/#/classify",
                        "data-scp": "flrk.0",
                        "data-trace-id": "",
                      },
                    },
                    [
                      _vm.backColor ||
                      _vm.newerList[0] ||
                      _vm.classifyTabs.imageUrl
                        ? _c("img", {
                            attrs: {
                              src: "//image1.51tiangou.com/tgou2/img2/index/icon-fenlei.png",
                              alt: "",
                              width: "40px",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: "//image1.51tiangou.com/tgou2/img2/index/icon-fenlei-dark.png",
                              alt: "",
                              width: "40px",
                            },
                          }),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.curClassify === -1,
              expression: "curClassify === -1",
            },
          ],
        },
        [
          _c("big-event-adv", {
            ref: "refBigEventAdv",
            attrs: {
              obj: _vm.bigEventEntryH || _vm.bigEventEntryW,
              page: "index",
            },
          }),
          _vm._v(" "),
          _c("station-mail", {
            ref: "refStationMail",
            staticStyle: { margin: "0 12px" },
            attrs: { page: "index", code: "14.sp" },
          }),
          _vm._v(" "),
          _vm.newerList[0] &&
          _vm.newerList[0].items &&
          _vm.newerList[0].items.length
            ? _c(
                "div",
                {
                  staticClass: "flex-container newer-contain",
                  staticStyle: { "margin-bottom": "8px" },
                  style: _vm.newerStyle,
                  attrs: {
                    "data-scp": _vm.newerList[0].scpCD,
                    "data-bk": _vm.newerList[0].bk,
                    "data-trace-id": "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changePage(
                        _vm.newerList[0].clickUrl,
                        _vm.newerList[0].scpCD
                      )
                    },
                  },
                },
                _vm._l(_vm.newerList[0].items, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: { width: "calc(25% - 4px)" },
                      attrs: {
                        "data-scp": item.scpCD,
                        "data-bk": item.bk,
                        "data-trace-id": "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.changePageTgs(
                            _vm.newerList[0].clickUrl,
                            _vm.newerList[0].scpCD,
                            _vm.newerList[0].bk
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "img-container borderR8 pos-r" },
                        [_c("img", { attrs: { src: item.imageUrl, alt: "" } })]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "fs-12",
                          staticStyle: { margin: "8px 0 4px" },
                        },
                        [_vm._v(_vm._s(item.title.slice(0, 6)))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "fs-12 text-center text-bolder text-red",
                          staticStyle: { "font-family": "DINAlternate-Bold" },
                        },
                        [
                          _vm._v("\n                    ￥"),
                          _c("span", { staticClass: "frs-30" }, [
                            _vm._v(_vm._s(item.price)),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "defaultBg" }, [
            _c("div", { staticClass: "deHeight" }),
            _vm._v(" "),
            (_vm.slider_cu && _vm.themeD === 2) || _vm.themeD === 3
              ? _c(
                  "div",
                  {
                    staticClass: "pos-r",
                    staticStyle: { "margin-bottom": "12px" },
                  },
                  [
                    _vm.slider_cu && _vm.themeD === 2
                      ? _c(
                          "div",
                          {
                            staticClass: "borderR8 marginh20",
                            staticStyle: { overflow: "hidden" },
                          },
                          [
                            _c("tg-slider", {
                              attrs: { data: _vm.slider_cu.items.slice(0, 5) },
                              on: { "slider-change": _vm.changeBg },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.themeD === 3
                      ? _c("div", { staticClass: "pos-r" }, [
                          _c(
                            "a",
                            {
                              staticClass: "dis-b",
                              attrs: {
                                "data-scp": "ad.0",
                                "data-bk":
                                  "adv-" +
                                  _vm.superBlock.items[0].data.items[0].id,
                                href:
                                  _vm.isWeixin &&
                                  (_vm.isList(
                                    _vm.superBlock.items[0].data.items[0]
                                  ) ||
                                    _vm.isRoom(
                                      _vm.superBlock.items[0].data.items[0]
                                    ))
                                    ? ""
                                    : _vm.superBlock.items[0].data.items[0]
                                        .clickUrl,
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm.isWeixin &&
                                  _vm.isList(
                                    _vm.superBlock.items[0].data.items[0]
                                  )
                                    ? _c(
                                        "wx-open-launch-weapp",
                                        {
                                          attrs: {
                                            username: _vm.webcast.userName,
                                            path: _vm.webcast.list,
                                          },
                                        },
                                        [
                                          _c(
                                            "script",
                                            {
                                              attrs: {
                                                type: "text/wxtag-template",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                '\n                                    <img style="max-width: 100%;display:block;"\n                                         src="' +
                                                  _vm._s(_vm.protocol) +
                                                  _vm._s(
                                                    _vm.superBlock.items[0].data
                                                      .items[0].imageUrl
                                                  ) +
                                                  '"\n                                         alt="">\n                                '
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm.isWeixin &&
                                      _vm.isRoom(
                                        _vm.superBlock.items[0].data.items[0]
                                      )
                                    ? _c(
                                        "wx-open-launch-weapp",
                                        {
                                          attrs: {
                                            username: _vm.webcast.userName,
                                            path: _vm.roomPath(
                                              _vm.superBlock.items[0].data
                                                .items[0]
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "script",
                                            {
                                              attrs: {
                                                type: "text/wxtag-template",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                '\n                                    <img style="max-width: 100%;display:block;"\n                                         src="' +
                                                  _vm._s(_vm.protocol) +
                                                  _vm._s(
                                                    _vm.superBlock.items[0].data
                                                      .items[0].imageUrl
                                                  ) +
                                                  '"\n                                         alt="">\n                                '
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c("img", {
                                        attrs: {
                                          width: "100%",
                                          alt: "",
                                          src: _vm.superBlock.items[0].data
                                            .items[0].imageUrl,
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.themeD === 3
                      ? _c(
                          "cmui-list",
                          { attrs: { col: 3 } },
                          _vm._l(
                            _vm.superBlock.items[1].data.items,
                            function (item, index) {
                              return _c(
                                "cmui-list-item",
                                { staticClass: "list-item" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { display: "block" },
                                      attrs: {
                                        href:
                                          _vm.isWeixin &&
                                          (_vm.isList(item) || _vm.isRoom(item))
                                            ? ""
                                            : item.clickUrl,
                                        "data-scp": "ads." + index,
                                        "data-bk": "adv-" + item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {},
                                        [
                                          _vm.isWeixin && _vm.isList(item)
                                            ? _c(
                                                "wx-open-launch-weapp",
                                                {
                                                  attrs: {
                                                    username:
                                                      _vm.webcast.userName,
                                                    path: _vm.webcast.list,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "script",
                                                    {
                                                      attrs: {
                                                        type: "text/wxtag-template",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        '\n                                        <img style="max-width:50%;"\n                                             src="' +
                                                          _vm._s(_vm.protocol) +
                                                          _vm._s(
                                                            item.imageUrl
                                                          ) +
                                                          '"\n                                             alt="">\n                                    '
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm.isWeixin && _vm.isRoom(item)
                                            ? _c(
                                                "wx-open-launch-weapp",
                                                {
                                                  attrs: {
                                                    username:
                                                      _vm.webcast.userName,
                                                    path: _vm.roomPath(item),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "script",
                                                    {
                                                      attrs: {
                                                        type: "text/wxtag-template",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        '\n                                        <img style="max-width:50%;"\n                                             src="' +
                                                          _vm._s(_vm.protocol) +
                                                          _vm._s(
                                                            item.imageUrl
                                                          ) +
                                                          '"\n                                             alt="">\n                                    '
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c("img", {
                                                attrs: {
                                                  alt: "",
                                                  width: "100%",
                                                  src: item.imageUrl,
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.themeD === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "pos-a bottom0 left0 right0",
                            staticStyle: {
                              height: "14px",
                              overflow: "hidden",
                              "pointer-events": "none",
                              "z-index": "1",
                            },
                          },
                          [
                            _c("div", {
                              staticStyle: {
                                background: "transparent",
                                height: "34px",
                                width: "110%",
                                "border-radius": "50%",
                                bottom: "0px",
                                position: "absolute",
                                "box-shadow": "rgb(248, 248, 248) 0 0 0 300px",
                                "margin-left": "-5%",
                              },
                              style: {
                                "box-shadow": _vm.backColor
                                  ? _vm.backColor + " 0 0 0 300px"
                                  : "rgb(248, 248, 248) 0 0 0 300px",
                              },
                              attrs: { id: "midShadow" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.bigEvent
            ? _c(
                "div",
                {
                  staticClass: "pos-r",
                  staticStyle: { margin: "12px 12px 0" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.bigEvent.clickUrl,
                        "data-scp": _vm.bigEvent.scpCD,
                        "data-bk": _vm.bigEvent.bk,
                        "data-trace-id": "",
                      },
                    },
                    [
                      _c("div", { staticClass: "img-container" }, [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: { src: _vm.bigEvent.imageUrl, alt: "" },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "pos-a bottom0 left0 right0",
                      staticStyle: { padding: "12px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-container" },
                        _vm._l(_vm.bigEvent.items, function (item) {
                          return _c("div", { staticClass: "img-container" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: item.clickUrl,
                                  "data-scp": item.scpCD,
                                  "data-bk": item.bk,
                                  "data-trace-id": "",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.imageUrl,
                                    width: "104",
                                    height: "104",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.bigEventMore
            ? _c(
                "div",
                {
                  staticClass: "overflow-h",
                  staticStyle: { margin: "12px 12px 0" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.bigEventMore.items, function (item, index) {
                      return _c("div", { staticClass: "span6 img-container" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: item.clickUrl,
                              "data-scp": "bigtwo." + index,
                              "data-bk": item.bk,
                              "data-trace-id": "",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: { src: item.imageUrl, alt: "" },
                            }),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.slider
            ? _c("tg-slider", {
                staticClass: "borderR8 overflow-h",
                staticStyle: { margin: "0 8px 8px" },
                attrs: { data: _vm.slider.items, "scp-c": "lunbo" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.funIcon
            ? _c(
                "div",
                {
                  staticClass: "flex-container bg-white borderR8 text-center",
                  class:
                    _vm.funIcon.items.length < 4 ||
                    _vm.funIcon.items.length === 6 ||
                    _vm.funIcon.items.length === 7 ||
                    _vm.funIcon.items.length === 9
                      ? "left"
                      : "round",
                  staticStyle: {
                    margin: "0 8px 8px",
                    padding: "12px 8px 8px",
                    "flex-wrap": "wrap",
                  },
                },
                _vm._l(_vm.funIcon.items, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "paddingb10",
                      style:
                        _vm.funIcon.items.length === 8
                          ? "width: 25%"
                          : "width: 20%",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "borderR8 overflow-h",
                          attrs: {
                            href: item.clickUrl,
                            "data-scp": item.scpCD,
                            "data-bk": item.bk,
                            "data-trace-id": "",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { margin: "auto" },
                            attrs: { src: item.imageUrl, alt: "", width: "48" },
                          }),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "fs-12 text-black",
                            domProps: { textContent: _vm._s(item.title) },
                          }),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.advLowPoster
            ? _c(
                "div",
                {
                  staticClass: "overflow-h",
                  staticStyle: { margin: "0 8px 8px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.advLowPoster.items, function (item, index) {
                      return _c("div", { staticClass: "span6 img-container" }, [
                        _c(
                          "a",
                          {
                            staticClass: "borderR8 overflow-h",
                            staticStyle: { width: "100%" },
                            attrs: {
                              href: item.clickUrl,
                              "data-scp": "ntwo." + index,
                              "data-bk": item.bk,
                              "data-trace-id": "",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: { src: item.imageUrl, alt: "" },
                            }),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.brandBar
            ? _c("div", { staticStyle: { margin: "0 8px 8px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "flex-container",
                    staticStyle: {
                      height: "48px",
                      "margin-bottom": "8px",
                      "margin-left": "-12px",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.brandBar.clickUrl,
                          "data-scp": "dszy.0",
                          "data-bk": _vm.brandBar.imgBk,
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.brandBar.imageUrl,
                            width: "104",
                            height: "48",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.brandBar.subTitle
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.brandBar.subClickUrl,
                              "data-scp": "dszy.1",
                              "data-bk": _vm.brandBar.subBk,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.brandBar.subTitle,
                                width: "174",
                                height: "48",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bg-white flex-container brandBar-container" },
                  _vm._l(_vm.brandBar.items, function (item) {
                    return _c(
                      "div",
                      {
                        staticClass: "brandBar-item",
                        style:
                          (_vm.brandBar.items.length === 4 ||
                            _vm.brandBar.items.length === 8) &&
                          "width: 25%;",
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: item.clickUrl,
                              "data-scp": item.scpCD,
                              "data-bk": item.bk,
                              "data-trace-id": "",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-container",
                                staticStyle: { "margin-bottom": "4px" },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.imageUrl,
                                    width: "56",
                                    height: "56",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            item.subTitle
                              ? _c("div", {
                                  staticClass:
                                    "text-center text-bolder text-black",
                                  staticStyle: {
                                    "font-size": "10px",
                                    "line-height": "10px",
                                  },
                                  domProps: {
                                    textContent: _vm._s(item.subTitle),
                                  },
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass: "text-center text-bolder",
                                    staticStyle: {
                                      "font-size": "10px",
                                      "line-height": "10px",
                                    },
                                  },
                                  [_vm._v(" ")]
                                ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.licenseBar
            ? _c(
                "a",
                {
                  attrs: {
                    href: _vm.licenseBar.clickUrl,
                    "data-scp": _vm.licenseBar.scpCD,
                    "data-trace-id": "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-container bg-white",
                      staticStyle: {
                        margin: "0 8px 8px",
                        padding: "7px 12px 7px 8px",
                        "border-radius": "8px",
                      },
                    },
                    [
                      _c("div", { staticClass: "img-container" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.licenseBar.imageUrl,
                            width: "48",
                            height: "28",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "fs-12 text-dark",
                        staticStyle: { "line-height": "34px" },
                        domProps: { textContent: _vm._s(_vm.licenseBar.title) },
                      }),
                      _vm._v(" "),
                      _vm._m(0),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.bizBar
            ? _c("div", { staticStyle: { margin: "0 8px 8px" } }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "span4 img-container poa-r" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.bizBar.items[0].clickUrl,
                          "data-scp": _vm.bizBar.items[0].scpCD,
                          "data-bk": _vm.bizBar.items[0].bk,
                          "data-trace-id": "",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: { src: _vm.bizBar.items[0].imageUrl, alt: "" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pos-a text-dark fs-10 paddingl10",
                            staticStyle: {
                              bottom: "6px",
                              left: "12px",
                              right: "0",
                              width: "auto",
                              height: "10px",
                              "line-height": "10px",
                            },
                          },
                          [
                            _vm.bizBar.items[0].subTitleList.length === 1
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.bizBar.items[0].subTitleList[0]
                                    ),
                                  },
                                })
                              : _vm.bizBar.items[0].subTitleList.length > 1
                              ? _c(
                                  "cmui-slider",
                                  {
                                    attrs: {
                                      options: _vm.sliderOptions,
                                      loop: true,
                                      page: false,
                                    },
                                  },
                                  _vm._l(
                                    _vm.bizBar.items[0].subTitleList,
                                    function (item) {
                                      return _c("cmui-slider-item", [
                                        _c("div", {
                                          domProps: {
                                            textContent: _vm._s(item),
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "span4 img-container pos-r" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.bizBar.items[1].clickUrl,
                          "data-scp": _vm.bizBar.items[1].scpCD,
                          "data-bk": _vm.bizBar.items[1].bk,
                          "data-trace-id": "",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: { src: _vm.bizBar.items[1].imageUrl, alt: "" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pos-a text-dark fs-10 paddingl10",
                            staticStyle: {
                              bottom: "6px",
                              left: "12px",
                              right: "0",
                              width: "auto",
                              height: "10px",
                              "line-height": "10px",
                            },
                          },
                          [
                            _vm.bizBar.items[1].subTitleList.length === 1
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.bizBar.items[1].subTitleList[0]
                                    ),
                                  },
                                })
                              : _vm.bizBar.items[1].subTitleList.length > 1
                              ? _c(
                                  "cmui-slider",
                                  {
                                    attrs: {
                                      options: _vm.sliderOptions,
                                      loop: true,
                                      page: false,
                                    },
                                  },
                                  _vm._l(
                                    _vm.bizBar.items[1].subTitleList,
                                    function (item) {
                                      return _c("cmui-slider-item", [
                                        _c("div", {
                                          domProps: {
                                            textContent: _vm._s(item),
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "span4 img-container pos-r" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.bizBar.items[2].clickUrl,
                          "data-scp": _vm.bizBar.items[2].scpCD,
                          "data-bk": _vm.bizBar.items[2].bk,
                          "data-trace-id": "",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: { src: _vm.bizBar.items[2].imageUrl, alt: "" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pos-a text-dark fs-10 paddingl10",
                            staticStyle: {
                              bottom: "6px",
                              left: "12px",
                              right: "0",
                              width: "auto",
                              height: "10px",
                              "line-height": "10px",
                            },
                          },
                          [
                            _vm.bizBar.items[2].subTitleList.length === 1
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.bizBar.items[2].subTitleList[0]
                                    ),
                                  },
                                })
                              : _vm.bizBar.items[2].subTitleList.length > 1
                              ? _c(
                                  "cmui-slider",
                                  {
                                    attrs: {
                                      options: _vm.sliderOptions,
                                      loop: true,
                                      page: false,
                                    },
                                  },
                                  _vm._l(
                                    _vm.bizBar.items[2].subTitleList,
                                    function (item) {
                                      return _c("cmui-slider-item", [
                                        _c("div", {
                                          domProps: {
                                            textContent: _vm._s(item),
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.advLimPoster
            ? _c("div", { staticStyle: { margin: "0 8px 8px" } }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "span4 img-container pos-r" }, [
                    _c(
                      "a",
                      {
                        staticClass: "borderR8 overflow-h",
                        attrs: {
                          href: _vm.advLimPoster.items[0].clickUrl,
                          "data-scp": _vm.advLimPoster.items[0].scpCD,
                          "data-bk": _vm.advLimPoster.items[0].bk,
                          "data-trace-id": "",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            src: _vm.advLimPoster.items[0].imageUrl,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pos-a text-white fs-10 text-center",
                            staticStyle: {
                              bottom: "10px",
                              left: "0",
                              right: "0",
                              width: "auto",
                              height: "10px",
                              "line-height": "10px",
                            },
                          },
                          [
                            _vm.advLimPoster.items[0].subTitleList.length === 1
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.advLimPoster.items[0].subTitleList[0]
                                    ),
                                  },
                                })
                              : _vm.advLimPoster.items[0].subTitleList.length >
                                1
                              ? _c(
                                  "cmui-slider",
                                  {
                                    attrs: {
                                      options: _vm.sliderOptions,
                                      loop: true,
                                      page: false,
                                    },
                                  },
                                  _vm._l(
                                    _vm.advLimPoster.items[0].subTitleList,
                                    function (item) {
                                      return _c("cmui-slider-item", [
                                        _c("div", {
                                          domProps: {
                                            textContent: _vm._s(item),
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "span4 img-container pos-r" }, [
                    _c(
                      "a",
                      {
                        staticClass: "borderR8 overflow-h",
                        attrs: {
                          href: _vm.advLimPoster.items[1].clickUrl,
                          "data-scp": _vm.advLimPoster.items[1].scpCD,
                          "data-bk": _vm.advLimPoster.items[1].bk,
                          "data-trace-id": "",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            src: _vm.advLimPoster.items[1].imageUrl,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pos-a text-white fs-10 text-center",
                            staticStyle: {
                              bottom: "10px",
                              left: "0",
                              right: "0",
                              width: "auto",
                              height: "10px",
                              "line-height": "10px",
                            },
                          },
                          [
                            _vm.advLimPoster.items[1].subTitleList.length === 1
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.advLimPoster.items[1].subTitleList[0]
                                    ),
                                  },
                                })
                              : _vm.advLimPoster.items[1].subTitleList.length >
                                1
                              ? _c(
                                  "cmui-slider",
                                  {
                                    attrs: {
                                      options: _vm.sliderOptions,
                                      loop: true,
                                      page: false,
                                    },
                                  },
                                  _vm._l(
                                    _vm.advLimPoster.items[1].subTitleList,
                                    function (item) {
                                      return _c("cmui-slider-item", [
                                        _c("div", {
                                          domProps: {
                                            textContent: _vm._s(item),
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "span4 img-container pos-r" }, [
                    _c(
                      "a",
                      {
                        staticClass: "borderR8 overflow-h",
                        attrs: {
                          href: _vm.advLimPoster.items[2].clickUrl,
                          "data-scp": _vm.advLimPoster.items[2].scpCD,
                          "data-bk": _vm.advLimPoster.items[2].bk,
                          "data-trace-id": "",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            src: _vm.advLimPoster.items[2].imageUrl,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pos-a text-white fs-10 text-center",
                            staticStyle: {
                              bottom: "10px",
                              left: "0",
                              right: "0",
                              width: "auto",
                              height: "10px",
                              "line-height": "10px",
                            },
                          },
                          [
                            _vm.advLimPoster.items[2].subTitleList.length === 1
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.advLimPoster.items[2].subTitleList[0]
                                    ),
                                  },
                                })
                              : _vm.advLimPoster.items[2].subTitleList.length >
                                1
                              ? _c(
                                  "cmui-slider",
                                  {
                                    attrs: {
                                      options: _vm.sliderOptions,
                                      loop: true,
                                      page: false,
                                    },
                                  },
                                  _vm._l(
                                    _vm.advLimPoster.items[2].subTitleList,
                                    function (item) {
                                      return _c("cmui-slider-item", [
                                        _c("div", {
                                          domProps: {
                                            textContent: _vm._s(item),
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.maibisheng
            ? _c(
                "div",
                {
                  staticClass: "bg-miaosha borderR8",
                  staticStyle: { margin: "0 8px 8px" },
                  style: _vm.maibisheng.imageUrl
                    ? "background-image: url('" +
                      _vm.maibisheng.imageUrl +
                      "');"
                    : "",
                  attrs: { "data-scp": _vm.maibisheng.scpCD },
                  on: {
                    click: function ($event) {
                      return _vm.changePage(
                        _vm.maibisheng.clickUrl,
                        _vm.maibisheng.scpCD
                      )
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "bg-count-down flex-container right" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fs-14 text-white",
                          staticStyle: { "margin-right": "8px" },
                        },
                        [_vm._v("本场结束剩余")]
                      ),
                      _vm._v(" "),
                      _c("cmui-countdown", {
                        staticClass: "fs-12 text-black",
                        attrs: {
                          "now-time": new Date().getTime(),
                          "end-time": _vm.msEndTime,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex-container top",
                      staticStyle: { "margin-top": "14px" },
                    },
                    _vm._l(
                      _vm.maibisheng.items.slice(0, 4),
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            staticClass: "text-center",
                            staticStyle: { width: "calc(25% - 5px)" },
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: item.clickUrl,
                                  "data-scp": item.scpCD,
                                  "data-bk": item.bk,
                                  "data-trace-id": "",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img-container pos-r",
                                    staticStyle: { overflow: "visible" },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { "border-radius": "8px" },
                                      attrs: { src: item.imageUrl, alt: "" },
                                    }),
                                    _vm._v(" "),
                                    item.price !== item.originalPrice
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "btn-red pos-a bg-red text-white fs-10 bottom0 overflow-h borderR8",
                                            staticStyle: {
                                              "line-height": "15px",
                                              padding: "0 6px",
                                              "white-space": "nowrap",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                补贴￥"
                                            ),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  parseFloat(
                                                    (
                                                      item.originalPrice -
                                                      item.price
                                                    ).toFixed(2)
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "btn-red pos-a bg-red text-white fs-10 bottom0 overflow-h borderR8",
                                            staticStyle: {
                                              "line-height": "15px",
                                              padding: "0 6px",
                                              "white-space": "nowrap",
                                            },
                                          },
                                          [_vm._v("秒杀")]
                                        ),
                                  ]
                                ),
                                _vm._v(" "),
                                item.title
                                  ? _c("div", {
                                      staticClass: "fs-12 text-black",
                                      staticStyle: {
                                        "margin-top": "8px",
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        "text-overflow": "clip",
                                        scale: "0.92",
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          item.title.slice(0, 7)
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "fs-12 text-black",
                                    staticStyle: {
                                      "line-height": "1",
                                      "font-family": "DINAlternate-Bold",
                                    },
                                  },
                                  [
                                    _vm._v("¥\n                            "),
                                    _c("span", {
                                      staticClass: "fs-16",
                                      domProps: {
                                        textContent: _vm._s(item.price),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.product && _vm.product.length
            ? _c("div", { staticStyle: { margin: "0 8px" } }, [
                _c(
                  "div",
                  { staticClass: "waterfall row", attrs: { id: "waterfall" } },
                  [
                    _vm.waterfallSlider.items.length
                      ? _c(
                          "div",
                          {
                            staticClass: "item item-slider span12",
                            class:
                              _vm.waterfallSlider.span &&
                              "item-span-" + _vm.waterfallSlider.span,
                            staticStyle: {
                              "border-radius": "8px",
                              overflow: "hidden",
                            },
                          },
                          [
                            _c("tg-slider", {
                              staticStyle: {
                                "border-radius": "8px",
                                overflow: "hidden",
                              },
                              attrs: {
                                data: _vm.waterfallSlider.items,
                                "scp-c": "slunbo",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.halfOneGrid, function (item) {
                      return _c(
                        "div",
                        {
                          staticClass: "item span12",
                          class: item.span && "item-span-" + item.span,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "img-container box",
                              staticStyle: { background: "transparent" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    href: item.clickUrl,
                                    "data-scp": item.scpCD,
                                    "data-bk": item.bk,
                                    "data-trace-id": "",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.imageUrl,
                                      width: "100%",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.productList, function (item, index) {
                      return [
                        index === 3 &&
                        _vm.waterfallAd &&
                        _vm.waterfallAd.items.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "item item-ad span12",
                                class:
                                  _vm.waterfallAd.items[0].span &&
                                  "item-span-" + _vm.waterfallAd.items[0].span,
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "img-container box" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          href: _vm.waterfallAd.items[0]
                                            .clickUrl,
                                          "data-scp":
                                            _vm.waterfallAd.items[0].scpCD,
                                          "data-bk":
                                            _vm.waterfallAd.items[0].bk,
                                          "data-trace-id": "",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.waterfallAd.items[0]
                                              .imageUrl,
                                            width: "100%",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        index === 3 && _vm.liveList && _vm.liveList.items.length
                          ? _c(
                              "div",
                              { staticClass: "item item-live span12" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "img-container box" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          href: _vm.liveList.items[0].clickUrl,
                                          "data-scp":
                                            _vm.liveList.items[0].scpCD,
                                          "data-bk": _vm.liveList.items[0].bk,
                                          "data-trace-id": "",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.liveList.items[0].imageUrl,
                                            width: "100%",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        index > 4 + _vm.liveList.items.length &&
                        _vm.computeIndex(index) % 5 === 0 &&
                        _vm.waterfallAd &&
                        _vm.waterfallAd.items.length >
                          Math.floor(_vm.computeIndex(index) / 5)
                          ? _c(
                              "div",
                              {
                                staticClass: "item item-ad span12",
                                class:
                                  _vm.waterfallAd.items[
                                    Math.floor(_vm.computeIndex(index) / 5)
                                  ].span &&
                                  "item-span-" +
                                    _vm.waterfallAd.items[
                                      Math.floor(_vm.computeIndex(index) / 5)
                                    ].span,
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "img-container box" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          href: _vm.waterfallAd.items[
                                            Math.floor(
                                              _vm.computeIndex(index) / 5
                                            )
                                          ].clickUrl,
                                          "data-scp":
                                            _vm.waterfallAd.items[
                                              Math.floor(
                                                _vm.computeIndex(index) / 5
                                              )
                                            ].scpCD,
                                          "data-bk":
                                            _vm.waterfallAd.items[
                                              Math.floor(
                                                _vm.computeIndex(index) / 5
                                              )
                                            ].bk,
                                          "data-trace-id": "",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.waterfallAd.items[
                                              Math.floor(
                                                _vm.computeIndex(index) / 5
                                              )
                                            ].imageUrl,
                                            alt: "",
                                            width: "100%",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item
                          ? _c(
                              "div",
                              {
                                staticClass: "item span12",
                                class: {
                                  "item-coupon":
                                    item.couponList && item.couponList.length,
                                  "item-long": item.longTitle,
                                  "item-latest": item.newProduct,
                                  "item-sortRank": item.itemSortRank,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "box",
                                    staticStyle: { "padding-bottom": "8px" },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.clickUrl,
                                          "data-store-id": item.storeId,
                                          "data-trace-id": item.traceId
                                            ? item.traceId
                                            : "",
                                          "data-scp": "pproduct." + index,
                                          "data-bk": "item-" + item.id,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "img-container" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: item.imageUrl,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "fs-14 lh-20",
                                            staticStyle: { padding: "5px 8px" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  overflow: "hidden",
                                                  "max-height": "40px",
                                                },
                                              },
                                              [
                                                item.source === 4 &&
                                                item.storeType === 3
                                                  ? _c("img", {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "text-top",
                                                      },
                                                      attrs: {
                                                        src: _vm.getTagByItem(
                                                          item
                                                        ).imgUrl,
                                                        alt: "",
                                                        height: "16px",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(item.title) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.couponList &&
                                        item.couponList.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "overflow-h",
                                                staticStyle: {
                                                  height: "20px",
                                                  "padding-left": "8px",
                                                },
                                              },
                                              [
                                                _c("coupon", {
                                                  attrs: {
                                                    data: item.couponList.slice(
                                                      0,
                                                      2
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              height: "27px",
                                              padding: "0 8px",
                                              "line-height": "27px",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "fs-10 text-bolder",
                                                },
                                                [_vm._v("¥")]
                                              ),
                                              _vm._v(" "),
                                              item.isPin
                                                ? _c("span", {
                                                    staticClass: "fs-20",
                                                    staticStyle: {
                                                      "margin-left": "-4px",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.groupPrice
                                                      ),
                                                    },
                                                  })
                                                : item.isSecKill
                                                ? _c("span", {
                                                    staticClass: "fs-20",
                                                    staticStyle: {
                                                      "margin-left": "-4px",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.buyoutPrice
                                                      ),
                                                    },
                                                  })
                                                : item.newPrice
                                                ? _c("span", {
                                                    staticClass: "fs-20",
                                                    staticStyle: {
                                                      "margin-left": "-4px",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.newPrice
                                                      ),
                                                    },
                                                  })
                                                : _c("span", {
                                                    staticClass: "fs-20",
                                                    staticStyle: {
                                                      "margin-left": "-4px",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.price
                                                      ),
                                                    },
                                                  }),
                                            ]),
                                            _vm._v(" "),
                                            item.originalPrice &&
                                            item.originalPrice !== item.price &&
                                            !item.isPin &&
                                            !item.isSecKill &&
                                            !item.newPrice &&
                                            !item.vipPrice
                                              ? _c("del", {
                                                  staticClass:
                                                    "fs-10 text-light",
                                                  staticStyle: {
                                                    "vertical-align": "top",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "¥" + item.originalPrice
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.isPin
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fs-10 text-bolder",
                                                    staticStyle: {
                                                      "vertical-align": "top",
                                                    },
                                                  },
                                                  [_vm._v("拼团价")]
                                                )
                                              : item.isSecKill
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fs-10 text-bolder",
                                                    staticStyle: {
                                                      "vertical-align": "top",
                                                    },
                                                  },
                                                  [_vm._v("秒杀价")]
                                                )
                                              : item.newPrice
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fs-10 text-bolder text-red",
                                                    staticStyle: {
                                                      "vertical-align": "top",
                                                    },
                                                  },
                                                  [_vm._v("新人价")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.soldQty
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "float-right fs-10 text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.soldQty) +
                                                        "人购买"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.newProduct
                                          ? _c(
                                              "p",
                                              {
                                                staticClass: "fs-10",
                                                staticStyle: {
                                                  color: "#F7B487",
                                                  "padding-left": "8px",
                                                },
                                              },
                                              [_vm._v("“近期上新单品”")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                item.itemSortRank
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "fs-10 dis-i",
                                        staticStyle: {
                                          width: "100%",
                                          background: "#FFF2D1",
                                          color: "#664E10",
                                          "line-height": "22px",
                                          padding: "8px 8px 0",
                                          "border-radius": "0 0 8px 8px",
                                          "margin-top": "-8px",
                                        },
                                        attrs: {
                                          href: item.itemSortRank.clickUrl,
                                          "data-scp": "spphb." + index,
                                          "data-bk":
                                            "id-" + item.itemSortRank.rankId,
                                        },
                                      },
                                      [
                                        item.itemSortRank.sort
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.itemSortRank.rankName
                                                ) +
                                                  " · 第" +
                                                  _vm._s(
                                                    item.itemSortRank.sort
                                                  ) +
                                                  "名"
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                "入选" +
                                                  _vm._s(
                                                    item.itemSortRank.rankName
                                                  )
                                              ),
                                            ]),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass: "float-right dis-i",
                                          staticStyle: { "margin-top": "5px" },
                                          attrs: {
                                            src: "//image1.51tiangou.com/tgou2/img2/index/icon-rank-arrow.png",
                                            alt: "",
                                            width: "8",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.curClassify !== -1,
              expression: "curClassify !== -1",
            },
          ],
          staticStyle: { padding: "0 8px" },
        },
        [
          _vm.classifySlider
            ? _c("tg-slider", {
                staticClass: "borderR8 overflow-h",
                staticStyle: { "margin-bottom": "8px" },
                attrs: { data: _vm.classifySlider.items, "scp-c": "lunbo" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.hotCategories &&
          _vm.hotCategories[0] &&
          _vm.hotCategories[0].items &&
          _vm.hotCategories[0].items.length
            ? _c(
                "div",
                { staticClass: "bg-white hot-classify" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "fs-16 text-black text-bolder",
                      staticStyle: {
                        "margin-bottom": "8px",
                        "padding-left": "8px",
                      },
                    },
                    [_vm._v("热门分类")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.hotCategories[0].items, function (item) {
                    return _c(
                      "div",
                      {
                        staticClass: "dis-i text-center fs-12 text-dark",
                        staticStyle: {
                          width: "20%",
                          "margin-bottom": "8px",
                          padding: "0 3px",
                          overflow: "hidden",
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: item.clickUrl,
                              "data-scp": item.scpCD,
                              "data-bk": item.bk,
                              "data-trace-id": "",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-container",
                                staticStyle: {
                                  margin: "0 auto 8px",
                                  width: "50px",
                                  height: "50px",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: { src: item.imageUrl, alt: "" },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(item.title.slice(0, 5))),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.classifyOneGrid
            ? _c(
                "div",
                {
                  staticClass: "overflow-h borderR8",
                  staticStyle: { "margin-bottom": "8px" },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "img-container" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.classifyOneGrid.items[0].clickUrl,
                            "data-scp": _vm.classifyOneGrid.items[0].scpCD,
                            "data-bk": _vm.classifyOneGrid.items[0].bk,
                            "data-trace-id": "",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              src: _vm.classifyOneGrid.items[0].imageUrl,
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.classifyTwoGrid
            ? _c(
                "div",
                {
                  staticClass: "overflow-h",
                  staticStyle: { "margin-bottom": "8px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.classifyTwoGrid.items, function (item, index) {
                      return _c("div", { staticClass: "span6 img-container" }, [
                        _c(
                          "a",
                          {
                            staticClass: "borderR8 overflow-h",
                            attrs: {
                              href: item.clickUrl,
                              "data-scp": item.scpCD,
                              "data-bk": item.bk,
                              "data-trace-id": "",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: { src: item.imageUrl, alt: "" },
                            }),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hotBrands && _vm.hotBrands.items
            ? _c(
                "div",
                {
                  staticClass: "bg-white borderR8",
                  staticStyle: { "margin-bottom": "8px", "padding-top": "8px" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "fs-16 text-black text-bolder",
                      staticStyle: {
                        "margin-bottom": "8px",
                        "padding-left": "8px",
                      },
                    },
                    [_vm._v("热门品牌")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "0 8px" } },
                    _vm._l(_vm.hotBrands.items.slice(0, 8), function (item) {
                      return _c(
                        "a",
                        {
                          staticClass: "dis-i hot-brands borderR8 overflow-h",
                          staticStyle: { border: "1px solid #f3f3f3" },
                          attrs: {
                            href: item.clickUrl,
                            "data-scp": item.scpCD,
                            "data-bk": item.bk,
                            "data-trace-id": "",
                          },
                        },
                        [_c("img", { attrs: { src: item.imageUrl, alt: "" } })]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.classifyProduct && _vm.classifyProduct.length
            ? _c(
                "div",
                { staticClass: "pos-r", staticStyle: { "padding-top": "8px" } },
                [
                  _c("div", {
                    staticClass: "bg-change-color pos-a top0 borderR8",
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "sort-list flex-container fs-12 text-dark pos-r paddingh10",
                      staticStyle: { "margin-bottom": "8px" },
                    },
                    [
                      _c(
                        "li",
                        {
                          class: _vm.curSort === 1 ? "active text-red" : "",
                          attrs: { "data-scp": "tabBar.0" },
                          on: {
                            click: function ($event) {
                              return _vm.tabSort(1)
                            },
                          },
                        },
                        [_vm._v("销量")]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass: "pos-r price-sort",
                          class: _vm.priceSort,
                          staticStyle: {
                            "padding-left": "23px",
                            "padding-right": "29px",
                          },
                          attrs: { "data-scp": "tabBar.1" },
                          on: {
                            click: function ($event) {
                              return _vm.tabSort(2)
                            },
                          },
                        },
                        [_vm._v("价格")]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          class: _vm.curSort === 3 ? "active text-red" : "",
                          attrs: { "data-scp": "tabBar.2" },
                          on: {
                            click: function ($event) {
                              return _vm.tabSort(3)
                            },
                          },
                        },
                        [_vm._v("综合")]
                      ),
                      _vm._v(" "),
                      _c("li", {
                        staticClass: "brand-screen",
                        class: {
                          "active text-red":
                            _vm.chooseFilterBrand.length > 0 ||
                            _vm.openBrandPanel,
                          open: _vm.openBrandPanel,
                        },
                        staticStyle: { "padding-right": "8px" },
                        attrs: { "data-scp": "filter.show" },
                        domProps: { textContent: _vm._s(_vm.chooseBrandName) },
                        on: {
                          click: function ($event) {
                            _vm.openBrandPanel = !_vm.openBrandPanel
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.openBrandPanel,
                          expression: "openBrandPanel",
                        },
                      ],
                      staticClass: "bg-white fs-12 text-black pos-a borderR8",
                      staticStyle: {
                        width: "100%",
                        "padding-left": "8px",
                        "padding-bottom": "44px",
                        "box-shadow": "0 4px 8px 0 rgba(0,0,0,.1)",
                        "z-index": "1",
                      },
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass: "scroll-container-y",
                          staticStyle: { "max-height": "178px" },
                        },
                        _vm._l(_vm.filterBrand, function (item, index) {
                          return _c(
                            "li",
                            {
                              staticClass: "dis-i",
                              class: item.choose ? "text-red" : "",
                              staticStyle: {
                                width: "50%",
                                "line-height": "30px",
                              },
                              attrs: { "data-scp": "filterBrand." + index },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFilterBrand(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-container pos-a text-center fs-12 left0 right0",
                          staticStyle: { bottom: "8px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex1 text-bslack",
                              staticStyle: {
                                background: "#e6e6e6",
                                "line-height": "32px",
                                "border-radius": "32px",
                                margin: "0 8px",
                              },
                              attrs: { "data-scp": "filter.reset" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetFilter()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex1 text-white bg-red",
                              staticStyle: {
                                "line-height": "32px",
                                "border-radius": "32px",
                                margin: "0 8px",
                              },
                              attrs: { "data-scp": "filter.set" },
                              on: {
                                click: function ($event) {
                                  return _vm.setFilter()
                                },
                              },
                            },
                            [_vm._v("确定")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "waterfall row jsProductPanel",
                      attrs: { id: "waterfall" },
                    },
                    [
                      _vm._l(_vm.classifyProductList, function (item, index) {
                        return [
                          item
                            ? _c(
                                "div",
                                {
                                  staticClass: "item span12",
                                  class: {
                                    "item-coupon":
                                      item.couponList && item.couponList.length,
                                    "item-long": item.longTitle,
                                    "item-latest": item.newProduct,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "box",
                                      staticStyle: { "padding-bottom": "8px" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: item.clickUrl,
                                            "data-store-id": item.storeId,
                                            "data-trace-id": item.traceId
                                              ? item.traceId
                                              : "",
                                            "data-scp": "pproduct." + index,
                                            "data-bk": "item-" + item.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "img-container" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: item.imageUrl,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fs-14 lh-20",
                                              staticStyle: {
                                                padding: "5px 8px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    overflow: "hidden",
                                                    "max-height": "40px",
                                                  },
                                                },
                                                [
                                                  item.source === 4 &&
                                                  item.storeType === 3
                                                    ? _c("img", {
                                                        staticStyle: {
                                                          "vertical-align":
                                                            "text-top",
                                                        },
                                                        attrs: {
                                                          src: _vm.getTagByItem(
                                                            item
                                                          ).imgUrl,
                                                          alt: "",
                                                          height: "16px",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(item.title) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          item.couponList &&
                                          item.couponList.length
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "overflow-h",
                                                  staticStyle: {
                                                    height: "20px",
                                                    "padding-left": "8px",
                                                  },
                                                },
                                                [
                                                  _c("coupon", {
                                                    attrs: {
                                                      data: item.couponList.slice(
                                                        0,
                                                        2
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "27px",
                                                padding: "0 8px",
                                                "line-height": "27px",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fs-10 text-bolder",
                                                  },
                                                  [_vm._v("¥")]
                                                ),
                                                _vm._v(" "),
                                                item.isPin
                                                  ? _c("span", {
                                                      staticClass: "fs-20",
                                                      staticStyle: {
                                                        "margin-left": "-4px",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.groupPrice
                                                        ),
                                                      },
                                                    })
                                                  : item.isSecKill
                                                  ? _c("span", {
                                                      staticClass: "fs-20",
                                                      staticStyle: {
                                                        "margin-left": "-4px",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.buyoutPrice
                                                        ),
                                                      },
                                                    })
                                                  : item.newPrice
                                                  ? _c("span", {
                                                      staticClass: "fs-20",
                                                      staticStyle: {
                                                        "margin-left": "-4px",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.newPrice
                                                        ),
                                                      },
                                                    })
                                                  : _c("span", {
                                                      staticClass: "fs-20",
                                                      staticStyle: {
                                                        "margin-left": "-4px",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.price
                                                        ),
                                                      },
                                                    }),
                                              ]),
                                              _vm._v(" "),
                                              item.originalPrice &&
                                              item.originalPrice !==
                                                item.price &&
                                              !item.isPin &&
                                              !item.isSecKill &&
                                              !item.newPrice &&
                                              !item.vipPrice
                                                ? _c("del", {
                                                    staticClass:
                                                      "fs-10 text-light",
                                                    staticStyle: {
                                                      "vertical-align": "top",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        "¥" + item.originalPrice
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.isPin
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "fs-10 text-bolder",
                                                      staticStyle: {
                                                        "vertical-align": "top",
                                                      },
                                                    },
                                                    [_vm._v("拼团价")]
                                                  )
                                                : item.isSecKill
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "fs-10 text-bolder",
                                                      staticStyle: {
                                                        "vertical-align": "top",
                                                      },
                                                    },
                                                    [_vm._v("秒杀价")]
                                                  )
                                                : item.newPrice
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "fs-10 text-bolder text-red",
                                                      staticStyle: {
                                                        "vertical-align": "top",
                                                      },
                                                    },
                                                    [_vm._v("新人价")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.soldQty
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "float-right fs-10 text-light",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.soldQty) +
                                                          "人购买"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          item.newProduct
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass: "fs-10",
                                                  staticStyle: {
                                                    color: "#F7B487",
                                                    "padding-left": "8px",
                                                  },
                                                },
                                                [_vm._v("“近期上新单品”")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-poster", { attrs: { data: _vm.modalPoster.item[0] } }),
      _vm._v(" "),
      _c("newer-pop", { ref: "newerPop" }),
      _vm._v(" "),
      _vm.showPopupPoster && !_vm.showRenewNotice
        ? _c("popup-poster", {
            attrs: { data: _vm.popupPoster },
            on: { "close-popup-poster": _vm.closePopupPoster },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showNotice && !_vm.showRenewNotice
        ? _c("bottom-notice", { attrs: { data: _vm.noticeList.items } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showRenewNotice
        ? _c("renew-notice", {
            attrs: { data: _vm.renewNoticeData },
            on: { "close-popup-poster": _vm.closePopupPoster },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("tg-loading"),
      _vm._v(" "),
      _vm.footer
        ? _c(
            "div",
            {
              staticClass: "fixed-bottom foot-nav-cu",
              staticStyle: {
                "z-index": "2",
                "background-size": "621px 55px",
                "background-position": "center",
              },
              style: { backgroundImage: "url(" + _vm.footer.imageUrl + ")" },
            },
            [
              _c(
                "div",
                { staticClass: "flex-container" },
                _vm._l(_vm.footer.items, function (item, $index) {
                  return _c(
                    "div",
                    {
                      staticClass: "flex1 text-center",
                      style: {
                        background:
                          $index === 0
                            ? "url(" +
                              item.activeImageUrl +
                              ") center no-repeat"
                            : "",
                        backgroundSize: "contain",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: $index === 0 ? "###" : item.clickUrl,
                            "data-scp":
                              "nav" + _vm.footscp.substr($index, 1) + ".00",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { height: "35px", margin: "0 auto" },
                            attrs: { src: item.imageUrl, alt: "" },
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "fs-10 text-white" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _c("tg-foot"),
      _vm._v(" "),
      _vm.isRain
        ? _c("rain-red-packet", {
            attrs: {
              page: "index",
              rulesMes: _vm.rulesMes,
              redPacketId: _vm.redPacketId,
              showIcon: _vm.showIcon,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-container" }, [
      _c("img", {
        attrs: {
          src: "//image1.51tiangou.com/tgou2/img2/index/icon_arrow_right.png",
          width: "24",
          height: "24",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }